import { Component, DestroyRef, inject, OnInit } from '@angular/core';
import { UntypedFormControl, UntypedFormGroup, Validators } from '@angular/forms';
import { StringValidators } from '../../shared/classes/string-validators';
import { AuthService } from '../../core/services/auth.service';
import { delay, first, map, switchMap } from 'rxjs/operators';
import { of, Subject, Subscription, timer } from 'rxjs';
import { AuthCode, AuthResult } from '../../core/services/auth.model';
import { ActivatedRoute, Params, Router } from '@angular/router';
import { environment } from '../../../environments/environment';
import { TranslateService } from '@ngx-translate/core';
import { marker as _ } from '@biesbjerg/ngx-translate-extract-marker';
import { InputError } from '../../shared/models/input-error';
import { AppDisplayService } from '../../core/services/app-display.service';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';
import { AppDisplay, movinMotionApp } from '../../core/services/app-display.model';
import { LoggerService } from '../../core/services/logger.service';
import * as Sentry from '@sentry/angular-ivy';
import { AppRoutingChangePassword, AppRoutingPasswordReset } from '../../app-routing.model';
import { PasswordService } from '../../core/services/password.service';
import { takeUntilDestroyed } from '@angular/core/rxjs-interop';
import { MixpanelService } from '@movinmotion/data-access-third-party';
import { MixpanelAuthService } from '../../core/services/mixpanel-auth.service';

@UntilDestroy()
@Component({
  selector: 'mm-page-login',
  templateUrl: './page-login.component.html',
  styleUrls: ['./page-login.component.scss'],
})
export class PageLoginComponent implements OnInit {
  /**
   * Form fields names
   */
  formFieldKeys = {
    email: 'email',
    password: 'password',
  };

  authCode = AuthCode;

  /**
   * Errors control for the global login form
   */
  loginErrors: InputError[] = [
    {
      isError: control => control?.hasError(AuthCode.failure),
      text$: this.translate.stream(_('mm.common.errors.loginFailure')),
    },
    {
      isError: control => control?.hasError(StringValidators.invalidMoreThanEightChars),
      text$: this.translate.stream(_('mm.common.errors.invalidPasswordMoreThanEightChars')),
    },
    {
      isError: control => control?.hasError(AuthCode.codeInvalid),
      text$: this.translate.stream(_('mm.common.errors.invalidLoginCode')),
    },
    {
      isError: control => control?.hasError(AuthCode.unknown),
      text$: this.translate.stream(_('mm.common.errors.unknown')),
    },
    {
      isError: control => control?.hasError(AuthCode.tooManyRequest),
      text$: this.translate.stream(_('mm.common.errors.tooManyRequest')),
    },
  ];

  /**
   * Errors control for the email input
   */
  emailErrors: InputError[] = [
    {
      isError: control => control?.hasError('required'),
      text$: this.translate.stream(_('mm.common.errors.emailRequired')),
    },
    {
      isError: control => control?.hasError('email'),
      text$: this.translate.stream(_('mm.common.errors.invalidEmail')),
    },
  ];

  /**
   * Errors control for the password input
   */
  passwordErrors: InputError[] = [
    {
      isError: control => control?.hasError('required'),
      text$: this.translate.stream(_('mm.common.errors.passwordRequired')),
    },
    {
      isError: control => control?.hasError(StringValidators.invalidContainsNumber),
      text$: this.translate.stream(_('mm.common.errors.invalidPasswordContainsNumber')),
    },
    {
      isError: control => control?.hasError(StringValidators.invalidContainsAlpha),
      text$: this.translate.stream(_('mm.common.errors.invalidPasswordContainsAlpha')),
    },
  ];

  /**
   * Handle loading state during submit login
   */
  submitLoading = false;

  /**
   * Handle global loading state during initialisation or redirection
   */
  globalLoading = false;

  /**
   * The login form
   */
  form = new UntypedFormGroup({
    [this.formFieldKeys.email]: new UntypedFormControl(null, [Validators.required, Validators.email]),
    [this.formFieldKeys.password]: new UntypedFormControl(null, [Validators.required]),
  });

  /**
   * Access to enum in template
   */
  availableAppDisplay = AppDisplay;

  /**
   * Subscription URL for Odalie users (Odalie display)
   */
  odalieSubscribeLink = environment.odalieSubscribeUrl;

  /**
   * Indicate if the authentification process failed from Movinmotion backend (user not found, not activated or token expired)
   */
  movinmotionAuthenticationError = false;
  currentConnexionMode = ConnexionMode.classical;
  ConnexionMode = ConnexionMode;
  magicLinkSended = false;
  magicLinkdisabled = false;
  mixpanel = inject(MixpanelService);
  mixpanelAuth = inject(MixpanelAuthService);
  currentAppAllowed = this.mixpanelAuth.currentAppAllowed;

  private delayBeforeReaskingMail = 60000;
  private magicLinkdisabledSubscription: Subscription;
  private destroyRef = inject(DestroyRef);

  constructor(
    public auth: AuthService,
    public logger: LoggerService,
    public router: Router,
    public route: ActivatedRoute,
    public translate: TranslateService,
    public appDisplayService: AppDisplayService,
    public passwordService: PasswordService,
  ) {}

  ngOnInit(): void {
    this.appDisplayService
      .setTitle$(this.translate.stream(_('mm.pages.login.title.head')))
      .pipe(untilDestroyed(this))
      .subscribe();

    this.form.get(this.formFieldKeys.email).valueChanges.subscribe(() => this.cleanMagicLink());
    this.globalLoading = true;
    this.auth
      .automaticProviderLogin$()
      .pipe(
        untilDestroyed(this),
        switchMap(result => {
          this.logger.debug('Result from automaticProviderLogin', result);
          if (!result?.loading && !result?.authResult) {
            return this.route.queryParamMap.pipe(
              map(params => {
                if (params.has(environment.queryParamsKeys.email) && params.get(environment.queryParamsKeys.email)) {
                  this.form.get(this.formFieldKeys.email).setValue(params.get(environment.queryParamsKeys.email));
                  this.form.get(this.formFieldKeys.email).markAsDirty();
                }
                if (
                  params.has(environment.queryParamsKeys.resetPassword) &&
                  params.get(environment.queryParamsKeys.resetPassword) === 'true'
                ) {
                  this.goToPasswordResetPage(true);
                }
                if (
                  params.has(environment.queryParamsKeys.changePassword) &&
                  params.get(environment.queryParamsKeys.changePassword) === 'true'
                ) {
                  this.goToPasswordChangePage(true);
                }
                if (
                  params.has(environment.queryParamsKeys.movinmotionAuthError) &&
                  params.get(environment.queryParamsKeys.movinmotionAuthError) === 'true'
                ) {
                  this.movinmotionAuthenticationError = true;
                }
                if (
                  params.has(environment.queryParamsKeys.email) &&
                  params.has(environment.queryParamsKeys.autoConnectUrl)
                ) {
                  this.currentConnexionMode = ConnexionMode.magicLink;
                  this.auth
                    .autoSignIn$(
                      params.get(environment.queryParamsKeys.email),
                      new URL(params.get(environment.queryParamsKeys.autoConnectUrl)),
                    )
                    .pipe(
                      first(),
                      switchMap(authResult => {
                        if (authResult.code === AuthCode.success) {
                          return this.auth.redirect$();
                        }
                        this.setFormError(authResult);
                        return of(null);
                      }),
                    )
                    .subscribe();
                }
                return result;
              }),
            );
          }
          return of(result);
        }),
      )
      .subscribe(({ loading, authResult }) => {
        this.globalLoading = loading;
        setTimeout(() => {
          this.setFormError(authResult);
        });
      });
  }

  haveOneError(form: UntypedFormGroup): boolean {
    return this.loginErrors
      .map(error => error.isError(form))
      .reduce((previousValue, currentValue) => previousValue || currentValue, false);
  }

  /**
   * The login or reset password submit of the form
   */
  submit(connexionMode: ConnexionMode): void {
    switch (connexionMode) {
      case ConnexionMode.classical:
        this.submitClassical();
        break;
      case ConnexionMode.magicLink:
        this.generateSignInMail();
        break;
    }
  }

  private mixpanelTrack(eventName: string, sendImmediately?: boolean): void {
    this.mixpanel.track({ eventName, sendImmediately });
  }

  private generateSignInMail(): void {
    this.submitLoading = true;
    const { [this.formFieldKeys.email]: email } = this.form.value;
    this.appDisplayService
      .getDisplay$()
      .pipe(
        first(),
        map(application => movinMotionApp[application]),
        switchMap(application =>
          this.passwordService.generateSignInMail$({
            email,
            application,
            redirectUrl: this.route.snapshot.queryParamMap.get(environment.queryParamsKeys.redirectUrl),
            offset: new Date()
              .toString()
              .match(/[+,-](\d{4})\s/g)[0]
              .trim(),
          }),
        ),
      )
      .subscribe(() => {
        this.submitLoading = false;
        this.magicLinkSended = true;
        this.magicLinkdisabled = true;

        this.magicLinkdisabledSubscription = of(true)
          .pipe(untilDestroyed(this), delay(this.delayBeforeReaskingMail))
          .subscribe(() => {
            this.magicLinkdisabled = false;
          });
      });
  }

  private cleanMagicLink() {
    if (this.magicLinkdisabledSubscription) {
      this.magicLinkdisabledSubscription.unsubscribe();
    }
    this.magicLinkdisabled = false;
    this.magicLinkSended = false;
  }

  /**
   * Set errors in form regarding authentication result provided by authentication service
   */
  private setFormError(authResult: AuthResult): void {
    switch (authResult?.code) {
      case AuthCode.failure:
      case AuthCode.tooManyRequest:
      case AuthCode.codeInvalid:
        this.form.setErrors({ [authResult.code]: true });
        break;
      case AuthCode.unknown:
        this.resetPasswordField();
        this.form.get(this.formFieldKeys.password).markAsPristine();
        this.form.get(this.formFieldKeys.password).markAsUntouched();
        this.form.setErrors({ [authResult.code]: true });
        this.form.markAsDirty();
        break;
    }
  }

  private resetPasswordField(): void {
    this.form.get(this.formFieldKeys.password).setValue(null);
  }

  /**
   * Go to password reset page
   *
   * @param replaceUrl If set to True, the browser will navigate to this page without leaving a trace of the current page in the browser history
   * @param track (Default: False) If set to True, track the redirection to mixpanel
   */
  goToPasswordResetPage(replaceUrl: boolean, track = false): void {
    if (track) {
      this.mixpanelTrack('Mot de passe oublié');
    }
    const queryParams: Params = {
      [environment.queryParamsKeys.changePassword]: null,
      [environment.queryParamsKeys.movinmotionAuthError]: null,
      [environment.queryParamsKeys.resetPassword]: null,
      [environment.queryParamsKeys.token]: null,
    };
    const { [this.formFieldKeys.email]: email } = this.form.value;
    let updateParamsPromise;
    if (email) {
      updateParamsPromise = this.updateParams({ [environment.queryParamsKeys.email]: email });
      queryParams[environment.queryParamsKeys.email] = this.form.value.email;
    }
    (updateParamsPromise ? updateParamsPromise : Promise.resolve()).then(() =>
      this.router.navigate([AppRoutingPasswordReset], { queryParams, replaceUrl, queryParamsHandling: 'merge' }),
    );
  }

  /**
   * Go to password change page
   *
   * @param replaceUrl (Default: False) If set to True, the browser will navigate to this page without leaving a trace of the current page in the browser history
   */
  goToPasswordChangePage(replaceUrl = false): void {
    const queryParams: Params = {
      [environment.queryParamsKeys.changePassword]: null,
      [environment.queryParamsKeys.movinmotionAuthError]: null,
      [environment.queryParamsKeys.resetPassword]: null,
      [environment.queryParamsKeys.token]: null,
    };
    const { [this.formFieldKeys.email]: email } = this.form.value;
    if (email) {
      queryParams[environment.queryParamsKeys.email] = this.form.value.email;
      this.appDisplayService
        .getDisplay$()
        .pipe(
          first(),
          map(application => movinMotionApp[application]),
          switchMap(application => this.passwordService.otpRequest$({ email, application })),
        )
        .subscribe();
    }
    this.router.navigate([AppRoutingChangePassword], { queryParams, replaceUrl, queryParamsHandling: 'merge' }).then();
  }

  magicLinkConnexionView() {
    this.mixpanelTrack('Connexion par email sans mot de passe');
    this.currentConnexionMode = ConnexionMode.magicLink;
  }

  classicalConexionView() {
    this.currentConnexionMode = ConnexionMode.classical;
    this.cleanMagicLink();
  }

  private updateParams(params: { [key: string]: string }) {
    return this.router.navigate([], {
      relativeTo: this.route,
      queryParams: params,
      queryParamsHandling: 'merge',
    });
  }

  private submitClassical() {
    this.submitLoading = true;
    this.movinmotionAuthenticationError = false;

    const submitAuth$ = new Subject<boolean>();

    submitAuth$.pipe(takeUntilDestroyed(this.destroyRef), first()).subscribe(res => {
      if (res) {
        this.submitAuth();
      }
    });
    this.mixpanelTrack('Se connecter - connexion', true);
    timer(1000)
      .pipe(first(), takeUntilDestroyed(this.destroyRef))
      .subscribe(() => {
        submitAuth$.next(true);
      });
  }

  private submitAuth(): void {
    const { [this.formFieldKeys.email]: email, [this.formFieldKeys.password]: password } = this.form.value;

    const auth$ = this.auth.signInWithEmailAndPasswordWrapper$(email, password).pipe(
      switchMap(result => {
        if (result.code === AuthCode.success) {
          return this.auth.redirect$();
        }

        Sentry.captureException(result.error);
        this.setFormError(result);
        return of(null);
      }),
      first(),
    );

    auth$.subscribe(ended => {
      if (!ended) {
        this.submitLoading = false;
      }
    });
  }
}

enum ConnexionMode {
  classical = 'classical',
  magicLink = 'magicLink',
}
