import { AuthProviderName, Environment, Language, MovinmotionApplication } from '../app/shared/models/environment';
import { NgxLoggerLevel } from 'ngx-logger';

export const environment: Environment = {
  production: true,
  defaultLanguage: Language.fr,
  logger: {
    level: NgxLoggerLevel.ERROR,
  },
  useFirebaseEmulator: false,
  firebase: {
    apiKey: 'AIzaSyDuf172YlUa-nfFA6r_P5lixp0SmhNCl_o',
    authDomain: 'accounts.app.movinmotion.com',
    databaseURL: 'https://movinmotion-prod.firebaseio.com',
    projectId: 'movinmotion-prod',
    storageBucket: 'movinmotion-prod.appspot.com',
    messagingSenderId: '861390979800',
    appId: '1:861390979800:web:1c01358e0c5f4b7ec24dad',
  },
  queryParamsKeys: {
    redirectUrl: 'mmr',
    token: 'mmt',
    entropy: 'mment',
    resetPassword: 'mmrp',
    changePassword: 'mmcp',
    email: 'mme',
    autoConnectUrl: 'mmac',
    language: 'mml',
    display: 'mmd',
    providerConnection: 'mmpc',
    movinmotionAuthError: 'mmae',
  },
  displayByAppHostEnabled: true,
  movinmotionAppUrls: {
    [MovinmotionApplication.core]: 'https://entreprise.app.movinmotion.com/public/login',
    [MovinmotionApplication.accounting]: 'https://compta.app.movinmotion.com/public/login',
    [MovinmotionApplication.worker]: 'https://salarie.app.movinmotion.com/login',
    [MovinmotionApplication.social]: 'https://app.movinmotion.com',
    [MovinmotionApplication.talents]: 'https://talents.app.movinmotion.com/film-france/login',
    [MovinmotionApplication.odalie]: 'https://odalie2.thalie-sante.org',
  },
  providers: {
    [AuthProviderName.myId]: {
      id: 'oidc.disney-myid',
      enabled: true,
    },
    [AuthProviderName.tohero]: {
      id: 'oidc.tohero',
      enabled: true,
    },
    [AuthProviderName.movinmotion]: {
      id: 'oidc.movinmotion',
      enabled: true,
    },
    [AuthProviderName.deezer]: {
      id: 'oidc.deezer',
      enabled: true,
    },
  },
  subscribeUrl: 'https://www.movinmotion.com/inscription/',
  filmFranceSubscribeUrls: {
    worker: 'https://salarie.app.movinmotion.com/film-france-talents/signup',
    talents: 'https://talents.app.movinmotion.com/film-france/signup',
  },
  odalieSubscribeUrl: 'https://odalie2.thalie-sante.org/cmbSignup',
  envName: 'prod',
  sentryDsn: 'https://14274fd8b65da1c5c06a5bc9f00b2be0@o1357468.ingest.sentry.io/4506156768755712',
  thirdParty: {
    mixpanel: {
      enabled: true,
    },
  },
};
