import { Environment } from '../models/environment';

export const environment: Environment = {
  environment: 'prod',
  production: true,
  api: {
    movinmotionBackend: 'https://back.app.movinmotion.com',
    movinmotionService: 'https://services.movinmotion.com',
  },
  appEndpoints: {
    talents: '/api/talent/v1',
    core: '/api/core/v1',
    filmfrance: 'api/filmfrance/v1',
    nda: '/api/nda/v1',
    movinmotion: '/api/movinmotion/v1',
  },
  authentication: {
    url: 'https://accounts.app.movinmotion.com',
    queryParamKeys: {
      token: 'mmt',
      entropy: 'mment',
      redirectUrl: 'mmr',
      resetPassword: 'mmrp',
      changePassword: 'mmcp',
      email: 'mme',
      movinmotionAuthError: 'mmae',
      language: 'mml',
    },
  },
  apps: {
    core: 'https://entreprise.app.movinmotion.com',
    worker: 'https://salarie.app.movinmotion.com',
    compta: 'https://compta.app.movinmotion.com',
    talents: 'https://talents.app.movinmotion.com',
    insurance: 'https://assurance.app.movinmotion.com',
    socialPlay: 'https://app.movinmotion.com',
    social: 'https://social.app.movinmotion.com',
    admin: 'https://admin.app.movinmotion.com',
    movinmotion: 'https://www.movinmotion.com',
  },
  thirdParty: {
    siren: {
      endpoint: 'https://api.insee.fr/entreprises/sirene/V3.11',
      token: 'f6306046-6677-38bf-bc0a-93122dfbd29d',
    },
    googlePlaces: 'AIzaSyD2rlNjqfHCDDDb86yhnlG-JHHTp6GRy1w',
    yousign: {
      sandbox: false,
    },
    mixpanel: {
      token: 'f09acb6b8094ff2b597f7f67aa8eeb88',
      name: 'MM prod',
    },
    axeptio: {
      clientId: '651a81b63cb8ed36b76be561',
      cookiesVersion: 'movinmotion - application - prod-fr',
    },
  },
  bucket: {
    avatars: 'avatars.storage.movinmotion.com',
  },
  cookieDomain: '.app.movinmotion.com',
};
